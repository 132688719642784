import React, {useState,useEffect,useCallback} from "react";
import { debounce } from "lodash";
import AuthUser from '../../../../Components/Auth/AuthUser';
import { useNavigate } from 'react-router-dom';
import {TextField, Box, Grid, Tooltip,Typography,Checkbox,Paper, FormControl, InputLabel, Select,OutlinedInput, MenuItem, Card, Button, Dialog, DialogTitle, DialogContent,Slider,InputAdornment,Tabs, Tab} from "@mui/material";
import {DateRangePicker,createStaticRanges} from "react-date-range";
import {calendarFilter, useDateSelectionRange,getInitialSelectionRange} from '../../../../Components/CommonFormula';
import { Chart } from "react-google-charts";
import { addDays } from "date-fns";
const TopPerformingCompany = ({topMetrics, handleLine, handleCompanyDetail, handleSeeAll, line, colorSeries, handleTitleClick, graph, options, selectedData, setSelectedData, chartOptions, setChartOptions,fetchRecords,kpis,getDateRange}) => {
    const { user } = AuthUser();
    const navigate = useNavigate();
    const [selectedJoining, setSelectedJoining] = useState("all");
    const [selectedPerformance, setSelectedPerformance] = useState("all");
    const [threshold, setThreshold] = useState([1, 10000]);
    const [selectedCompanies, setSelectedCompanies] = useState([]);
    const [errors, setErrors] = useState(false); 
    const { selectionRange, setSelectionRange, startDate, endDate,clearFilter } =useDateSelectionRange(getInitialSelectionRange('performanceDashboard'));
    const { selectionRange: selectionRange1, setSelectionRange: setSelectionRange1, startDate: startDate1, endDate: endDate1, clearFilter: clearFilter1 } = useDateSelectionRange(getInitialSelectionRange('performanceDashboard'));
    const sideBar = calendarFilter();
    const staticRanges = [...createStaticRanges(sideBar)];
    useEffect(() => {
        if (user.roles_id < 3) {
        const value={ selectedJoining: "all",
                        selectedPerformance: "all",
                        threshold: [0, 10000],
                        }
        fetchRecords(value);

        } else {
            navigate('/login');
        }
    }, []);

    useEffect(() => {
        if (line?.records?.length) {
            setSelectedCompanies(line.records.slice(0, 5).map(combine => combine.company_name));
        }
    }, [line]);

    const handleCheckboxChange = (companyName, line, graph, colorSeries, setSelectedData, setChartOptions) => {
        setSelectedCompanies(prevSelected => {
            // Prevent unchecking if only one company is selected
            if (prevSelected.length === 1 && prevSelected.includes(companyName)) {
                return prevSelected; // Keep the selection unchanged
            }
    
            const updatedCompanies = prevSelected.includes(companyName)
                ? prevSelected.filter(name => name !== companyName) // Remove if more than one selected
                : [...prevSelected, companyName]; // Add if not selected
    
            // Call handleTitleClick with updated selections
            handleTitleClick(updatedCompanies, line, graph, colorSeries, setSelectedData, setChartOptions);
            return updatedCompanies;
        });
    };
    const handleThresholdChange = (newThreshold) => {
        let [min, max] = newThreshold.map(value => value === "" ? "" : Number(value)); // Convert to number, allow empty string
        if (min !== "" && max !== "" && min > max) { // Only validate when both are numbers
            setErrors(true);
        } else {
            setErrors(false);
        }
        setThreshold([min, max]);
    };

    
    const handleDateChange = (value, setSelectionRange) => {
        setSelectionRange([{ ...getDateRange(value), key: "selection" }]);
    };
    
    const handleJoiningChange = (event) => {
        const value = event.target.value;
        setSelectedJoining(value);
        handleDateChange(value, setSelectionRange);
    };
    
    const handlePerformanceChange = (event) => {
        const value = event.target.value;
        setSelectedPerformance(value);
        handleDateChange(value, setSelectionRange1);
    };
    
    const handleSubmit = async()=>{
        const value={ 
            selectedJoining: selectedJoining,
            selectedPerformance: selectedPerformance,
            threshold: threshold,
            startDate1: startDate1,
            endDate1: endDate1,     
            startDate: startDate,   
            endDate: endDate,
        }
        fetchRecords(value);
    }

    return(
        <>
            <Box sx={{ display: 'flex', gap: 2, marginBottom: 3, width: '100%' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={3}>
                    <FormControl fullWidth variant="outlined">
                        <InputLabel>Company Registration Date</InputLabel>
                        <Select
                        value={selectedJoining}
                        onChange={handleJoiningChange}
                        className="blue-border"
                        input={<OutlinedInput label="Company Registration Date" />}
                        >
                        <MenuItem value="all">All</MenuItem>
                        <MenuItem value="customDate">Custom Date</MenuItem>
                        <MenuItem value="thisWeek">This Week</MenuItem>
                        <MenuItem value="lastWeek">Last Week</MenuItem>
                        <MenuItem value="thisMonth">This Month</MenuItem>
                        <MenuItem value="lastMonth">Last Month</MenuItem>
                        </Select>
                    </FormControl>
                    </Grid>
                    <Grid item xs={12} md={3}>
                    <FormControl fullWidth variant="outlined">
                        <InputLabel>Company Performance Period</InputLabel>
                        <Select
                        value={selectedPerformance}
                        onChange={handlePerformanceChange}
                        className="blue-border"
                        input={<OutlinedInput label="Company Performance Period" />}
                        >
                        <MenuItem value="all">All</MenuItem>
                        <MenuItem value="customDate">Custom Date</MenuItem>
                        <MenuItem value="thisWeek">This Week</MenuItem>
                        <MenuItem value="lastWeek">Last Week</MenuItem>
                        <MenuItem value="thisMonth">This Month</MenuItem>
                        <MenuItem value="lastMonth">Last Month</MenuItem>
                        </Select>
                    </FormControl>
                    </Grid>
                    {/* Threshold Fields */}
                    <Grid item xs={12} md={3}>
                    <FormControl fullWidth>
                        <InputLabel shrink>Threshold</InputLabel>
                        <Box
                            sx={{
                                border: "1px solid rgba(0, 0, 0, 0.23)",
                                borderRadius: "4px",
                                padding: "8px",
                                display: "flex",
                                alignItems: "center",
                                gap: "8px",
                            }}
                        >
                            <TextField
                                type="number"
                                value={threshold[0] === "" ? "" : threshold[0]}
                                onChange={(e) => handleThresholdChange([e.target.value, threshold[1]])}
                                inputProps={{ min: 0, max: 10000 }}
                                size="small"
                                sx={{ width: "45%" }}
                            />
                            <Typography variant="body2"> - </Typography>
                            <TextField
                                type="number"
                                value={threshold[1] === "" ? "" : threshold[1]}
                                onChange={(e) => handleThresholdChange([threshold[0], e.target.value])}
                                inputProps={{ min: 0, max: 10000 }}
                                size="small"
                                sx={{ width: "45%" }}
                            />
                        </Box>
                        
                    </FormControl>
                    {/* Error Message */}
                    {errors && (
                        <Box sx={{ color: "error.main", fontSize: 12, mt: 1 }}>
                            Min value cannot be greater than max value
                        </Box>
                    )}
                    </Grid>
        
        
                    <Grid item xs={12} md={3}>
                    <Button color="primary" variant="contained" onClick={handleSubmit} fullWidth>
                        Submit
                    </Button>
                    </Grid>
                    {/* Show Date Picker When "Custom" is Selected */}
                    {selectedJoining !== "all" && (
                        <Grid item xs={12} md={3}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-multiple-name-label">Registration Custom Date</InputLabel>
                                <Select
                                    labelId="demo-multiple-name-label"
                                    id="demo-multiple-name"
                                    value="date"
                                    input={<OutlinedInput label="Registration Custom Date" />}
                                >
                                    <MenuItem value="date">
                                    {startDate ? (
                                        <>
                                        {startDate} To {endDate}
                                        </>
                                    ) : ''}
                                    </MenuItem>
                                    <DateRangePicker
                                        className="custom-date-picker"
                                        onChange={(item) => setSelectionRange([item.selection])}
                                        showSelectionPreview={true}
                                        moveRangeOnFirstSelection={false}
                                        months={2}
                                        ranges={selectionRange}
                                        direction="horizontal"
                                        maxDate={addDays(new Date(), -1)}
                                        staticRanges={[]}
                                    />
                                </Select>
                            </FormControl>
                        </Grid>
                    )}

                    {selectedPerformance !== "all" && (
                        <Grid item xs={12} md={3}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-multiple-name-label">Performance Custom Date</InputLabel>
                                <Select
                                    labelId="demo-multiple-name-label"
                                    id="demo-multiple-name"
                                    value="date"
                                    input={<OutlinedInput label="Performance Custom Date" />}
                                >
                                    <MenuItem value="date">
                                    {startDate1 ? (
                                        <>
                                        {startDate1} To {endDate1}
                                        </>
                                    ) : ''}
                                    </MenuItem>
                                    <DateRangePicker
                                        className="custom-date-picker"
                                        onChange={(item) => setSelectionRange1([item.selection])}
                                        showSelectionPreview={true}
                                        moveRangeOnFirstSelection={false}
                                        months={2}
                                        ranges={selectionRange1}
                                        direction="horizontal"
                                        maxDate={addDays(new Date(), -1)}
                                        staticRanges={[]}
                                    />
                                </Select>
                            </FormControl>
                        </Grid>
                    )}
                </Grid>
            </Box>
            {/* KPIs Section */}
            <Grid container spacing={2} sx={{ marginBottom: 3 }}>
            {kpis.map((kpi, index) => { const backgroundColors = ['#D5ECF5', '#D8F2D8', '#D5ECF5', '#D8F2D8']; return (
                <Grid item xs={12} sm={6} md={3} key={index}>
                    <Card sx={{ padding: 2, backgroundColor: backgroundColors[index] }}>
                        <Typography variant="h6">{kpi.split(":")[0]}</Typography>
                        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>{kpi.split(":")[1]}</Typography>
                    </Card>
                </Grid>
                ); })}
            </Grid>
            <Box className="common-container">
                {Array.isArray(topMetrics) && topMetrics.length > 0 && (
                    <Paper sx={{ padding: 3, boxShadow: 3, marginBottom: 3 }}>
                        <Grid container spacing={2}>
                            {topMetrics.map((combine, index) => ( 
                                <Grid item xs={12} sm={6} md={3} key={index}>
                                    <Card sx={{ padding: 1, bgcolor: '#2E67BC', position: 'relative', zIndex: 1 }}>
                                        <Typography 
                                        variant="h6" 
                                        sx={{ textAlign: 'center', color: 'white', cursor: 'pointer' }} 
                                        onClick={() => handleLine(combine)}
                                        >
                                        {combine.title}
                                        </Typography>
                                    </Card>
                                    {combine.records.slice(0, 5).map((record, companyIndex) => (
                                        <Box 
                                        key={companyIndex} 
                                        sx={{ padding: 1, bgcolor: companyIndex % 2 === 0 ? '#FFFFFF' : '#EBF4FF', textAlign: 'center' }}
                                        >
                                            <Typography 
                                                variant="body2" 
                                                sx={{ color: '#000000', cursor: 'pointer' }} 
                                                onClick={() => handleCompanyDetail(record)}
                                            >
                                                {record.company_name}
                                            </Typography>
                                        </Box>
                                    ))}
                                    {combine.records.length > 5 && (
                                        <Box sx={{ padding: 1, textAlign: 'right', marginTop: 1 }}>
                                            <Typography 
                                                variant="body2" 
                                                sx={{ color: '#2E67BC', fontWeight: 'bold', cursor: 'pointer' }} 
                                                onClick={() => handleSeeAll(combine)}
                                            >
                                                See All
                                            </Typography>
                                        </Box>
                                    )}
                                </Grid>
                            ))}
                        </Grid>
                    </Paper>
                )}

                {/* Graph Section */}
                {Array.isArray(line?.records) && line.records.length > 0 && (
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={8}>
                            <Paper sx={{ padding: 2, marginBottom: 3 }}>
                                {topMetrics.length > 0 && (
                                    <FormControl variant="outlined">
                                        <Select
                                        value={line}  
                                        onChange={(e) => handleLine(e.target.value)}
                                        renderValue={(selected) => selected?.title || "Select an option"} // Display title
                                        >
                                        {topMetrics.map((combine, index) => (
                                            <MenuItem key={index} value={combine}>
                                            {combine.title}
                                            </MenuItem>
                                        ))}
                                        </Select>
                                    </FormControl>
                                )}
                                <Chart chartType="LineChart" width="100%" height="400px" data={selectedData || graph} options={selectedData ? chartOptions : options} />
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Paper sx={{ padding: 3, marginBottom: 3 }}>
                                <Grid container spacing={1}>
                                {line?.records?.slice(0, 5).map((combine, index) => (
                                    <Grid item xs={12} key={index}>
                                        <Paper sx={{ display: 'flex', alignItems: 'center', padding: 3, color: '#00695c', marginBottom: 2, }}>
                                        <Checkbox
                                            checked={selectedCompanies.includes(combine.company_name)}
                                            onChange={() => handleCheckboxChange(combine.company_name,line, graph, colorSeries, setSelectedData, setChartOptions)}
                                        />
                                            <Box key={index}  sx={{ width: 20, height: 20, bgcolor: colorSeries[index].color, marginRight: 1 }} />
                                            <Typography variant="body1" sx={{ textAlign: 'left',cursor: 'pointer' }} >{combine.company_name}</Typography>
                                        </Paper>
                                    </Grid>
                                    ))}
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                )}
            </Box>
        </>
    );
}

export default TopPerformingCompany;