import React, {useMemo, useState, useEffect,useCallback} from 'react'
import { debounce } from "lodash";
import AuthUser from '../../../Components/Auth/AuthUser';
import DefaultLayout from '../../../Components/DefaultLayout';
import {TextField, Box, Grid, Tooltip,Typography,Checkbox,Paper, FormControl, InputLabel, Select,OutlinedInput, MenuItem, Card, Button, Dialog, DialogTitle, DialogContent,DialogActions,Slider,InputAdornment,Tabs, Tab} from "@mui/material";
import {DateRangePicker,createStaticRanges} from "react-date-range";
import MaterialReactTable from 'material-react-table';
import { Chart } from "react-google-charts";
import Backdrop from '@mui/material/Backdrop';
import Freez from '../../../Components/Loader/Loader';
import { useDispatch } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import swal from "sweetalert";
import { postData } from '../../../redux/apiSlice';
import 'react-calendar/dist/Calendar.css';
import "react-circular-progressbar/dist/styles.css";
import { useNavigate } from 'react-router-dom';
import {calendarFilter, useDateSelectionRange,getInitialSelectionRange} from '../../../Components/CommonFormula';
import { ExportToCsv } from 'export-to-csv';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Moment from 'moment';
import config from '../../../AppConfig';
import TopPerformingCompany from './Components/TopPerformingCompany';
import UnderPerformingCompany from './Components/UnderPerformingCompany';
import { Close, Message, Phone, Email } from "@mui/icons-material";
import AiFillPhone from '@mui/icons-material/Phone';
import {openOrFocusWindow } from  '../../../Components/CommonFormula';
import { addDays, startOfWeek, endOfWeek, startOfMonth, endOfMonth, subWeeks,subDays, subMonths,min  } from "date-fns";
import * as Yup from 'yup';
import { Formik } from 'formik';
const PerformanceDashboard = () => {
  const dispatch = useDispatch();
  const { user } = AuthUser();
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState(0);
  const [registered, setRegistered] = useState([]);
  const [totalEnrollments, setTotalEnrollments] = useState([]);
  const [activeEnrollments, setActiveEnrollments] = useState([]);
  const [commission , setCommission] = useState([]);
  const [referral , setReferral] = useState([]);
  const [snapshot , setSnapshot] = useState([]);
  const [totalCompanies , settotalCompanies] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openCompanyModal, setOpenCompanyModal] = useState(false);
  const [selectedMetric, setSelectedMetric] = useState({ title: '', records: [] });
  const colorSeries = [{ color: '#8E2EBC' },{ color: '#3FAC19' },{ color: '#2E67BC' },{ color: '#BC2E7B' },{color :'#2EB0BC'}];
  const colorSeries1 = [{ color: '#F7E115' },{ color: '#EE8A51' },{ color: '#FF0808' },{ color: '#AC8C19'},{color :'#931111'}];
  const [line, setLine] = useState({ title: '', records: [] });
  const [graph, setGraph] = useState();
  const [selectedData, setSelectedData] = useState(graph);
  const [line1, setLine1] = useState({ title: '', records: [] });
  const [graph1, setGraph1] = useState();
  const [selectedData1, setSelectedData1] = useState(graph1);
  const [rowSelection, setRowSelection] = useState({}); 
  const [isPerformancePeriodValue, setIsPerformancePeriodValue] = useState(false);
  const [totalReferralsMemberCount, setTotalReferralsMemberCount] = useState([]);
  const [totalReferralsActiveMemberCount, setTotalReferralsActiveMemberCount] = useState([]);
  // const [errors, setErrors] = useState({ min: '', max: '' });
  const [topCompanies, setTopCompanies] = useState({
    enrollments: [],
    referrals: [],
    // retentionData: [],
    leads:[],
    profitabilityData : [],
  });
  const [poorCompanies, setPoorCompanies] = useState({
    enrollments: [],
    referrals: [],
    // retentionData: [],
    leads:[],
    profitabilityData : [],
  });
  const [freez, setFreez] = React.useState(false);
  const [companyDetails, setCompanyDetails] = useState(null);
  const [openSMSDialog, setOpenSMSDialog] = useState(false);
  const [smsMessage, setSmsMessage] = useState('');
  const [selectedPhoneNumbers, setSelectedPhoneNumbers] = useState([]);
  const customSortFunction = (rowA, rowB) => {
    const a = rowA.original.company_name || ''; // Fallback to an empty string if null or undefined
    const b = rowB.original.company_name || '';
    return a.localeCompare(b);
  };

  const handleFreezStop = () => {
      setFreez(false);
  };
  const handleFreezStart = () => {
      setFreez(true);
  };
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const handleSeeAll = (metric) => {
    setSelectedMetric(metric);
    setOpenModal(true);  // Open modal
  };

  const handleCompanyDetail = (records) => {
    setCompanyDetails(records);
    // window.location.href= `${config.BASE_URL}affiliate/companypanel1/${companyId}`;
    setOpenCompanyModal(true);
  };

  // Function to close the modal
  const handleClose = () => {
    setOpenModal(false);
    setRowSelection({});
  };

  // Function to close company modal
  const handleCloseCompanyModal = () => {
    setOpenCompanyModal(false);
  };

  const columns = useMemo(() => {
    const baseColumns = [
      {
        accessorFn: (data) => `${data.company_name ? data.company_name : 'N/A'}`,
        id: 'company_name',
        header: 'Company Name',
        sortingFn: customSortFunction,
        enableSorting: true,
        size: 50,
        Cell: ({ renderedCellValue, row }) => (
          <Typography>{row.original.company_name ? row.original.company_name.trim() : 'N/A'}</Typography>
        ),
      },
      {
        accessorFn: (data) => `${data.registered_date ? data.registered_date : 'N/A'}`,
        id: 'registeredOn',
        header: 'Registered On',
        size: 50,
        Cell: ({ renderedCellValue, row }) => (
          <Typography>{row.original.registered_date ? Moment( row.original.registered_date).tz('America/Los_Angeles').format('MM-DD-YYYY') : 'N/A'}</Typography>
        ),
      },
    ];
    // Conditionally add the 'total' column if selectedMetric.title is 'Enrollments'
    if (selectedMetric.title === 'Enrollments') {
      baseColumns.push(
        {
          accessorFn: (data) =>(data.startDateCount !== undefined && data.startDateCount !== null ? data.startDateCount : 'N/A'),
          id: 'start',
          header: 'Start Date Enrollment',
          size: 50,
          Cell: ({ renderedCellValue, row }) => (
            <Typography>{row.original.startDateCount !== undefined && row.original.startDateCount !== null ? row.original.startDateCount : 'N/A'}</Typography>
          ),
        },
        {
          accessorFn: (data) =>(data.endDateCount !== undefined && data.endDateCount !== null ? data.endDateCount : 'N/A'),
          id: 'end',
          header: 'End Date Enrollment',
          size: 50,
          Cell: ({ renderedCellValue, row }) => (
            <Typography>{row.original.endDateCount !== undefined && row.original.endDateCount !== null ? row.original.endDateCount : 'N/A'}</Typography>
          ),
        },
        {
          accessorFn: (data) =>(data.till_active_count !== undefined && data.till_active_count !== null ? data.till_active_count : 'N/A'),
          id: 'total',
          header: 'Active Enrollments',
          size: 50,
          Cell: ({ renderedCellValue, row }) => (
            <Typography>{row.original.till_active_count !== undefined && row.original.till_active_count !== null ? row.original.till_active_count : 'N/A'}</Typography>
          ),
        },
        {
          accessorFn: (data) => (data.totalEnrollements !== undefined && data.totalEnrollements !== null ? data.totalEnrollements : 'N/A'),
          id: 'totalEnrollements',
          header: 'Total Enrollments',
          size: 50,
          Cell: ({ renderedCellValue, row }) => (
            <Typography>{row.original.totalEnrollements !== undefined && row.original.totalEnrollements !== null ? row.original.totalEnrollements : 'N/A'}</Typography>
          ),
        }
      );
    }else if(selectedMetric.title === 'Referrals'){
      baseColumns.push(
        {
          accessorFn: (data) =>(data.startDateCount !== undefined && data.startDateCount !== null ? data.startDateCount : 'N/A'),
          id: 'start',
          header: 'Start Date Referral',
          size: 50,
          Cell: ({ renderedCellValue, row }) => (
            <Typography>{row.original.startDateCount !== undefined && row.original.startDateCount !== null ? row.original.startDateCount : 'N/A'}</Typography>
          ),
        },
        {
          accessorFn: (data) =>(data.endDateCount !== undefined && data.endDateCount !== null ? data.endDateCount : 'N/A'),
          id: 'end',
          header: 'End Date Referral',
          size: 50,
          Cell: ({ renderedCellValue, row }) => (
            <Typography>{row.original.endDateCount !== undefined && row.original.endDateCount !== null ? row.original.endDateCount : 'N/A'}</Typography>
          ),
        },
        {
          accessorFn: (data) => (data.total !== undefined && data.total !== null ? data.total : 'N/A'),
          id: 'total',
          header: 'Total Referrals',
          size: 50,
          Cell: ({ renderedCellValue, row }) => (
            <Typography>{row.original.total !== undefined && row.original.total !== null ? row.original.total : 'N/A'}</Typography>
          ),
        },
      );
    }else if(selectedMetric.title === 'Leads'){
      baseColumns.push(
        {
          accessorFn: (data) =>(data.startDateCount !== undefined && data.startDateCount !== null ? data.startDateCount : 'N/A'),
          id: 'start',
          header: 'Start Date Lead',
          size: 50,
          Cell: ({ renderedCellValue, row }) => (
            <Typography>{row.original.startDateCount !== undefined && row.original.startDateCount !== null ? row.original.startDateCount : 'N/A'}</Typography>
          ),
        },
        {
          accessorFn: (data) =>(data.endDateCount !== undefined && data.endDateCount !== null ? data.endDateCount : 'N/A'),
          id: 'end',
          header: 'End Date Lead',
          size: 50,
          Cell: ({ renderedCellValue, row }) => (
            <Typography>{row.original.endDateCount !== undefined && row.original.endDateCount !== null ? row.original.endDateCount : 'N/A'}</Typography>
          ),
        },
        {
          accessorFn: (data) => (data.total !== undefined && data.total !== null ? data.total : 'N/A'),
          id: 'total',
          header: 'Total Lead',
          size: 50,
          Cell: ({ renderedCellValue, row }) => (
            <Typography>{row.original.total !== undefined && row.original.total !== null ? `${row.original.total}` : 'N/A'}</Typography>
          ),
        },
      )
    }else{
      baseColumns.push(
        {
          accessorFn: (data) => (data.total !== undefined && data.total !== null ? data.total : 'N/A'),
          id: 'total',
          header: 'Total',
          size: 50,
          Cell: ({ renderedCellValue, row }) => (
            <Typography>{row.original.total !== undefined && row.original.total !== null ? `${row.original.total} %` : 'N/A'}</Typography>
          ),
        },
      );
    }
    if(isPerformancePeriodValue == true) {
      baseColumns.push(
        {
          accessorFn: (data) => (data.difference !== undefined && data.difference !== null ? data.difference : 'N/A'),
          id: 'Growth',
          header: 'Growth %',
          size: 50,
          Cell: ({ renderedCellValue, row }) => (
            <Typography>{row.original.difference !== undefined && row.original.difference !== null ? `${row.original.difference} %` : 'N/A'}</Typography>
          ),
        },
      );
    }
    
    return baseColumns;
  }, [selectedMetric]);

  //  csv code start here
  const csvOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    fileName: 'Performance',
    headers : [
      'Company Name', 
      'Registered On', 
      ...(selectedMetric.title === 'Enrollments' 
        ? ['Start Date Enrollment','End Date Enrollment','Active Enrollments', 'Total Enrollments'] 
        : selectedMetric.title === 'Referrals' 
          ? ['Start Date Referral','End Date Referral','Total Referrals'] 
            : selectedMetric.title === 'Leads'
            ?  ['Start Date Lead','End Date Lead','Total Lead'] 
          : ['Total ']),
      ...(isPerformancePeriodValue ? ['Growth %'] : [])
    ],
  };
  const csvExporter = new ExportToCsv(csvOptions);
  const handleExportRows  = (rows) => {
    const exportedData = rows.map((row) => {
      const data = {
        company_name: row.company_name || "N/A",
        registeredOn: row.registered_date || "N/A",
      };
      if (selectedMetric.title === 'Enrollments') {
        data.start = row.startDateCount || "0";
        data.end=row.endDateCount || "0";
        data.activeEnrollements = row.total || "0";
        data.total=row.totalEnrollements || "0";
      }else if (selectedMetric.title === 'Referrals')
      {
        data.start = row.startDateCount || "0";
        data.end=row.endDateCount || "0";
        data.total=row.total || "0";
      }else if (selectedMetric.title === 'Leads')
      {
        data.start = row.startDateCount || "0";
        data.end=row.endDateCount || "0";
        data.total=row.total || "0";
      }
      else{
        data.total= `${row.total} %` || "0";
      }
      if(isPerformancePeriodValue == true)
      {
        data.growth = `${row.difference} %` || "0 %";
      }
      
      return data;
    });

    // Convert data to CSV format
    const csvContent = convertToCSV(exportedData);

    // Create a Blob object from the CSV data
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });

    // Create a download link element
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = csvOptions.fileName + '.csv';

    // Append the link to the document body
    document.body.appendChild(link);

    // Programmatically click the link to trigger the file download
    link.click();

    // Clean up the temporary URL and remove the link from the document
    URL.revokeObjectURL(link.href);
    link.remove();
  };
  const convertToCSV = (data) => {
    const headers = csvOptions.headers.join(',') + '\n';
    const rows = data.map((row) => {
      return Object.values(row).map((value) => {
        if (typeof value === 'string' && value.includes(',')) {
          return `"${value}"`;
        }
        return value;
      }).join(',');
    }).join('\n');
    return headers + rows;
  };
  // csv code end here

  const createChartOptions = (colorSeries) => ({
    hAxis: { title: 'Date' },
    legend: { position: 'none' },
    series: Array.isArray(colorSeries) 
      ? Object.assign({}, ...colorSeries.map((color, index) => ({ [index]: color }))) 
      : { 0: { color: colorSeries } }
  });

  // Use the function to create options
  const options = createChartOptions(colorSeries);
  const options1 = createChartOptions(colorSeries1);

  const [chartOptions, setChartOptions] = useState(options);
  const [chartOptions1, setChartOptions1] = useState(options1);
  const fetchRecords=async(values)=>{
    const loginUser = JSON.parse(localStorage.getItem('user'));
    if (loginUser.roles_id >= 3) {
        navigate('/login');
        return false;
    }

    handleFreezStart()
    if(values.selectedPerformance != "all") {
      setIsPerformancePeriodValue(true);
    }
      const apiUrl = '/performanceDashboard';
      const args = {
          tab:tabValue == 0 ? "top" : "poor",
          joiningDate:values.selectedJoining,
          performancePeriod:values.selectedPerformance,
          fromDate: values.startDate1,
          toDate: values.endDate1,
          registerdFromDate:values.startDate,
          registerdToDate: values.endDate,
          threshold: values.threshold
      }
      const res = await dispatch(postData({ apiUrl, args })).unwrap();
      if (res) {
          if (res.data) {
              setRegistered(res.data.totalCompanies);
              setTotalEnrollments(res.data.totalEnrollements);
              setActiveEnrollments(res.data.activeEnrollements);
              setCommission(res.data.totalCommissionPaid);
              setReferral(res.data.totalReferrals);
              setSnapshot(res.data.snapshotLeads);
              settotalCompanies(res.data.allCompanies);
              setTotalReferralsMemberCount(res.data.totalReferralsMemberCount);
              setTotalReferralsActiveMemberCount(res.data.totalReferralsActiveMemberCount);
              setTopCompanies({
                enrollments: res.data.topEnrollementCompanies || [],
                referrals: res.data.topReferralCompanies || [],
                // retentionData: res.data.topretentionCompanies || [],
                leads:res.data.topleadsCompanies || [],
                profitabilityData: res.data.topgrossProfitCompanies || [],
              });
              
              setPoorCompanies({
                enrollments: res.data.bottomEnrollmentCompanies || [],
                referrals: res.data.bottomReferralCompanies || [],
                // retentionData: res.data.bottomRetentionCompanies || [],
                leads:res.data.bottomLeadsCompanies || [],
                profitabilityData: res.data.bottomGrossProfitCompanies || [],
              });

              handleLine({ title: "Enrollments", records: res.data.topEnrollementCompanies || [] });
              handleLine1({ title: "Enrollments", records: res.data.bottomEnrollmentCompanies || [] });
              handleFreezStop()
          }
          else {
            handleFreezStop();
            swal({
                title: "Failed",
                text: res.message,
                icon: "error",
                button: "Ok",
            })
          }
    }else {
      handleFreezStop()
      swal({
        title: "Failed",
        text: "Something Went Wrong.",
        icon: "error",
        button: "Ok",
    })
    }

  }

  const kpis = [
    `Registered Companies: ${registered}`,
    `Enrollments: ${activeEnrollments} / ${totalEnrollments}`,
    // `Commission Paid: $${commission.toLocaleString()}`, // Format commission with commas
    `Referrals: ${referral}`, // Format referral with commas
    `Referral Enrollments: ${totalReferralsActiveMemberCount} / ${totalReferralsMemberCount}`, // Format referral with commas
    `Snapshot Leads : ${snapshot}`,
  ];

  const topMetrics = [
    { title: "Enrollments", records: topCompanies.enrollments },
    { title: "Referrals", records: topCompanies.referrals },
    // { title: "Retentions", records: topCompanies.retentionData },
    { title: "Leads", records: topCompanies.leads },
    { title: "Profitability", records: topCompanies.profitabilityData}
  ];
  const poorMetrics = [
    { title: "Enrollments", records: poorCompanies.enrollments },
    { title: "Referrals", records: poorCompanies.referrals },
    // { title: "Retentions", records: poorCompanies.retentionData },
    { title: "Leads", records: poorCompanies.leads },
    { title: "Profitability", records: poorCompanies.profitabilityData}
  ];

  const handleLine = (metric) => {
    setSelectedData(null);
    processMetricData(metric, setLine, setGraph);
  };
  
  const handleLine1 = (metric) => {
    setSelectedData1(null);
    processMetricData(metric, setLine1, setGraph1);
  };

  const processMetricData = (metric, setLine, setGraph) => {
    setLine(metric);
    const limitedRecords = metric?.records?.slice(0, 5);
  
    if (limitedRecords) {
      const companyNames = limitedRecords.map(company => company.company_name);
      const monthlyData = {};
  
      limitedRecords.forEach(company => {
        const enrollments = company.graphData;
        for (const month in enrollments) {
          if (!monthlyData[month]) {
            monthlyData[month] = {};
          }
          monthlyData[month][company.company_name] = enrollments[month];
        }
      });
  
      const finalData = [['Date', ...companyNames]];
      for (const month in monthlyData) {
        const row = [month];
        companyNames.forEach(name => {
          row.push(monthlyData[month][name] || 0); // Fill with 0 if no data
        });
        finalData.push(row);
      }
      setGraph(finalData);
    }
  };

  const handleTitleClick = (selectedCompanies, line, graph, colorSeries, setSelectedData, setChartOptions) => {
    if (!line.records || line.records.length === 0 || selectedCompanies.length === 0) {
        setSelectedData([]);
        setChartOptions(options);
        return;
    }

    const filteredData = [['Date', ...selectedCompanies]]; // Header row with all selected companies

    for (let i = 1; i < graph.length; i++) {
        const row = graph[i];
        const rowData = [row[0]]; // First column is the date

        selectedCompanies.forEach(company => {
            const companyDataIndex = graph[0].indexOf(company);
            rowData.push(companyDataIndex !== -1 ? row[companyDataIndex] : null);
        });

        filteredData.push(rowData);
    }

    // Assign unique colors to each selected company
    const seriesColors = {};
    selectedCompanies.forEach((company, index) => {
        const companyIndex = line.records.findIndex(record => record.company_name === company);
        seriesColors[index] = { color: companyIndex !== -1 ? colorSeries[companyIndex].color : '#000000' };
    });

    const multiCompanyOptions = {
        ...options,
        series: seriesColors // Apply colors dynamically
    };

    setSelectedData(filteredData);
    setChartOptions(multiCompanyOptions);
  };

  const getDateRange = (value) => {
    const today = new Date();
    if (value === "thisWeek") {
        return {
            startDate: startOfWeek(today),
            endDate: min([endOfWeek(today), subDays(today, 1)]),
        };
    } else if (value === "lastWeek") {
        return {
            startDate: startOfWeek(subWeeks(today, 1)),
            endDate: endOfWeek(subWeeks(today, 1)),
        };
    } else if (value === "thisMonth") {
        return {
            startDate: startOfMonth(today),
            endDate: min([endOfMonth(today), subDays(today, 1)]),
        };
    } else if (value === "lastMonth") {
        return {
            startDate: startOfMonth(subMonths(today, 1)),
            endDate: endOfMonth(subMonths(today, 1)),
        };
    }
  };

  const handleSendSMS = () => {
    const selectedIndexes = Object.keys(rowSelection).map(Number); 
  
    const selectedRecords = selectedIndexes.map((index) => selectedMetric.records[index]);
  
    const selectedPhoneNumbers = selectedRecords
      .map((record) => {
        return record?.phone_no;
      })
      .filter(Boolean); // remove null/undefined/empty
  
    if (selectedPhoneNumbers.length === 0) {
      alert("Please select at least one record with a phone number.");
      return;
    }
  
    setSelectedPhoneNumbers(selectedPhoneNumbers);
    setOpenSMSDialog(true);
  };
  
  const smsSchema = Yup.object().shape({
    message: Yup.string().required('Message is required'),
  });

  const ContentLayout =
  <>
    <Box sx={{ padding: 2 }}>
      {/* Title Section */}
      <Typography variant="h4" gutterBottom>
        Performance Dashboard ({totalCompanies} Companies)
      </Typography>

      <Tabs value={tabValue} onChange={handleTabChange} sx={{ marginBottom: 2 }} TabIndicatorProps={{ sx: { backgroundColor: "#3FAC19" } }}>
        <Tab label="Top Performing Companies" sx={{ color: "#555", "&.Mui-selected": { color: "#3FAC19", fontWeight: "bold" }}} />
        <Tab label="Under Performing Companies" sx={{ color: "#555", "&.Mui-selected": { color: "#3FAC19", fontWeight: "bold" }}}/>
      </Tabs>

      
      {tabValue === 0 && <TopPerformingCompany topMetrics={topMetrics} handleLine={handleLine} handleCompanyDetail={handleCompanyDetail} handleSeeAll={handleSeeAll} line={line} colorSeries={colorSeries} handleTitleClick={handleTitleClick} graph={graph} options={options} selectedData={selectedData} setSelectedData={setSelectedData} chartOptions={chartOptions} setChartOptions={setChartOptions} fetchRecords={fetchRecords} kpis={kpis} getDateRange={getDateRange}/>}
      {tabValue === 1 && <UnderPerformingCompany poorMetrics={poorMetrics} handleLine1={handleLine1} handleCompanyDetail={handleCompanyDetail} handleSeeAll={handleSeeAll} line1={line1} colorSeries1={colorSeries1} handleTitleClick={handleTitleClick} graph1={graph1} options1={options1} selectedData1={selectedData1} setSelectedData1={setSelectedData1} chartOptions1={chartOptions1} setChartOptions1={setChartOptions1} fetchRecords={fetchRecords} kpis={kpis} getDateRange={getDateRange}/>}
    </Box>
    {/* All Companies List */}
    <Dialog 
      open={openModal} 
      onClose={handleClose} 
      fullWidth 
      maxWidth="lg"
      PaperProps={{
        sx: {
            borderRadius: "12px",
            padding: "20px"
        }
      }}
    >
      <DialogTitle className="dialog-title">
        <Typography variant="h6" fontWeight="bold">Insight Of {selectedMetric.title}</Typography>
        <IconButton onClick={handleClose}>
            <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <MaterialReactTable
          columns={columns}
          data={selectedMetric.records.slice(0, 10)}
          enableRowSelection
          getRowId={(row) => row.id}
          onRowSelectionChange={(updater) => {
            const newSelection = typeof updater === "function" ? updater(rowSelection) : updater;
            setRowSelection(newSelection); // ✅ allow multiple selection
          }}
          state={{ rowSelection }}
          enableColumnFilters={false}
          initialState={{ density: 'compact' }}
          positionToolbarAlertBanner="bottom"
          enableDensityToggle={false}
          enableSorting={true}
          muiTablePaperProps={{
            sx: { backgroundColor: "white" },
          }}
          muiTableHeadCellProps={{
              sx: {
                  backgroundColor: "#2A68B3",
                  color: "white",
                  fontWeight: "bold",
              },
          }}
          muiTableBodyRowProps={({ index }) => ({
              sx: { backgroundColor: index % 2 === 0 ? "white" : "#F6FAFF" },
          })}
          renderTopToolbarCustomActions={({ table }) => (
            <Box
              sx={{
                  display: 'flex',
                  justifyContent: 'space-between', // Keeps the layout consistent
                  gap: '1rem',
                  p: '0.5rem',
                  flexWrap: 'wrap',
                  width: '100%',
              }}
            >
              <Button
                sx={{ bgColor: "#2A68B3" }}
                disabled={selectedMetric.records.length === 0}
                onClick={() =>
                  handleExportRows(selectedMetric.records)
                }
                startIcon={<FileDownloadIcon />}
                variant="contained"
                className='export'
              >
                CSV
              </Button>
            </Box>
          )}
        />
        <Box mt={1} sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
          <Button
            sx={{ backgroundColor: "#2A68B3" }} // ✅ Fixed 'bgColor' to 'backgroundColor'
            variant="contained"
            className="export"
            onClick={handleSendSMS}
          >
            Send SMS
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
    {/* Indiviual Company */}
    <Dialog 
      open={openCompanyModal} 
      onClose={handleCloseCompanyModal} 
      maxWidth="md" 
      fullWidth 
      PaperProps={{
          sx: {
              borderRadius: "12px",
              padding: "20px"
          }
      }}
    >
      <DialogTitle className="dialog-title">
        <Typography variant="h6" fontWeight="bold">Performance Of {companyDetails?.company_name}</Typography>
        <IconButton onClick={handleCloseCompanyModal}>
            <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {/* Affiliate Info */}
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
          <Grid container spacing={2} sx={{ marginBottom: 3 }}>
            <Grid item xs={4}>
                <Typography variant="body2" >Affiliate Name :</Typography>
                <Typography variant="subtitle1" fontWeight="bold" className="common-value">{companyDetails?.first_name} {companyDetails?.last_name}</Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography variant="body2" >Phone Number :</Typography>
                <Typography variant="subtitle1" fontWeight="bold" className="common-value">{companyDetails?.phone_no}</Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography variant="body2" >Email :</Typography>
                <Typography variant="subtitle1" fontWeight="bold" className="common-value">{companyDetails?.email}</Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography variant="body2" >Address :</Typography>
                <Typography variant="subtitle1" fontWeight="bold" className="common-value">{companyDetails?.address}</Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography variant="body2" >City :</Typography>
                <Typography variant="subtitle1" fontWeight="bold" className="common-value">{companyDetails?.city}</Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography variant="body2" >State :</Typography>
                <Typography variant="subtitle1" fontWeight="bold" className="common-value">{companyDetails?.state_code}</Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography variant="body2" >Zipcode :</Typography>
                <Typography variant="subtitle1" fontWeight="bold" className="common-value">{companyDetails?.zip_code}</Typography>
            </Grid>
          </Grid>
        </Box>

        {/* Performance Metrics */}
        <Box sx={{ border: "1px solid #ccc", borderRadius: "10px", padding: 2, display: "flex", justifyContent: "space-between", textAlign: "center" }}>
          <Box>
            <Typography variant="body2">Enrollments</Typography>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              {companyDetails?.till_active_count}/{companyDetails?.till_total_count}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body2">Referrals</Typography>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              {companyDetails?.referrals}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body2">Referrals Enrollments</Typography>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              {companyDetails?.totalReferralsActiveMemberCount}/{companyDetails?.totalReferralsMemberCount}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body2">Leads</Typography>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            {companyDetails?.leads}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body2">Profitability</Typography>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              {companyDetails?.grossProfit} %
            </Typography>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>

    <Dialog
      open={openSMSDialog}
      onClose={() => setOpenSMSDialog(false)}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        sx: {
          borderRadius: '12px',
          padding: '20px',
        },
      }}
    >
      <Formik
        initialValues={{ message: '' }}
        validationSchema={smsSchema}
        onSubmit={async (values, { resetForm }) => {
          handleFreezStart();
          const apiUrl = '/sendSMSAffiliates';
          const args = {
            message: values.message,
            phoneNo: selectedPhoneNumbers,
          }
          const res = await dispatch(postData({ apiUrl, args })).unwrap();
          if(res) {
              if (res.success === true) {
                  swal({
                      title: "Success",
                      text: res.data,
                      icon: "success",
                      button: "Ok",
                  })
                  setOpenSMSDialog(false);
                  setRowSelection({});
                  handleFreezStop();
              }
          }else{
            alert("Something Went Wrong");
            handleFreezStop();
          }
          resetForm();
        }}
      >
        {({values,errors,touched,handleChange,handleSubmit,handleBlur,}) => (
          <form onSubmit={handleSubmit}>
            <DialogTitle className="dialog-title">
              <Typography variant="h6" fontWeight="bold">
                Send SMS
              </Typography>
              <IconButton onClick={() => setOpenSMSDialog(false)}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>

            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                label="Message"
                type="text"
                fullWidth
                multiline
                rows={4}
                name="message"
                value={values.message}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.message && Boolean(errors.message)}
                helperText={touched.message && errors.message}
              />
            </DialogContent>

            <DialogActions>
              <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: 'center', width: '100%' }}>
                <Grid item lg={6} md={6} sm={6} xs={6}>
                  <Button
                    variant="contained"
                    sx={{ minWidth: 150 }}
                    onClick={() => setOpenSMSDialog(false)}
                  >
                    CLOSE
                  </Button>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={6}>
                  <Button
                    variant="contained"
                    sx={{ minWidth: 150 }}
                    color="success"
                    type="submit"
                  >
                    Send Message
                  </Button>
                </Grid>
              </Grid>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  </>
  return (
    <div>
      <DefaultLayout content={ContentLayout} />
      {/* loader code start */}
      <div>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={freez}
        >
          <Freez />
        </Backdrop>
      </div>
      {/* loader code end */}
    </div>
  );
};

export default PerformanceDashboard;