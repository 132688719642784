import React, { useEffect,useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Box, Typography, Tabs, Tab } from "@mui/material";
import CompanyDetailsTab from "./Components/CompanyDetailsTab";
import CoBrandPIDsTab from "./Components/CoBrandPIDsTab";
import AIDsTab from "./Components/AIDsTab";
import CompanyUsersTab from "./Components/CompanyUsersTab";
import BankDetailsTab from "./Components/BankDetailsTab";
import W9DetailsTab from "./Components/W9DetailsTab";
import DefaultLayout from '../../../Components/DefaultLayout';
import { useDispatch } from 'react-redux';
import { fetchData, postData } from '../../../redux/apiSlice';
import AuthUser from '../../../Components/Auth/AuthUser';
import Backdrop from '@mui/material/Backdrop';
import Freez from '../../../Components/Loader/Loader'
const CompanyDetailHome = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { http, user } = AuthUser();
    const { id } = useParams();
    const [tabValue, setTabValue] = useState(0);
    const [company_details, setcompanyDetails] = useState([]);
    const [epicProStatus, setEpicProStatus] = React.useState(false);
    const [aids, setAids] = useState([]);
    const [pids, setPids] = useState([]);
    const [aid, setAid] = useState();
    const [aidusers, setAidUsers] = useState([]);
    const [cobrandpids, setCobrandPids] = useState([]);
    const [logoImg, setLogoImg] = useState();
    const [bank_details, setBankDetails] = useState([]);
    const [taxFlag, setTaxFlag] = useState(false);
    const [tax_details, setTaxDetails] = useState([]);
    const [userTypeList, setUserTypeList] = useState([]);
    const [aidNames, setAidNames] = React.useState([]);
    const [companies, setCompanies] = useState([]);
    //bank history
    const [filterhistory, setFilterHistory] = useState([]);
    const [freez, setFreez] = React.useState(false);
    const handleFreezStop = () => {
        setFreez(false);
    };
    const handleFreezStart = () => {
        setFreez(true);
    };
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };
    const loginUser = JSON.parse(localStorage.getItem('user'));
    useEffect(() => {
        fetchCompanyDetails();
        fetchCompanyBankDetails();
        fetchTaxDetails();
        if (loginUser.roles_id !== 4 && loginUser.roles_id !== 5) {
            fetchUserTypes();
        }
        getAidlist();
        fetchCompanyList();
    }, []);

    const fetchCompanyDetails = async () => {
        if(loginUser.roles_id >= 3 && loginUser.roles_id <= 5) {
            if(loginUser.company_master_id != id) {
                navigate('/login');
            }
        }
        handleFreezStart();
        const apiUrl = '/companyDetails/' + id;
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if (res) {
            if (res.company_details.is_disabled == 1) {
                navigate('/companypanel');
            }
            handleFreezStop();
            setcompanyDetails(res.company_details);
            setEpicProStatus(res.company_details.epic_pro_status);
            setLogoImg(res.company_details.company_logo_url)
            setPids(res.pids);
            setAids(res.aids);
            setAidUsers(res.aid_users);
            setCobrandPids(res.coBrand);
        }
    };

    const fetchCompanyBankDetails = async () => {
        handleFreezStart();
        const apiUrl = '/fetch-company-bank-details/' + id;
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if (res) {
            handleFreezStop();
            if (res.companyBankDetails && res.companyBankDetails.company_master_id && res.companyBankDetails.company_master_id != null) {
                setBankDetails(res.companyBankDetails);
                setFilterHistory(Object.values(res.updatedAllAttributes));
            } else {
                handleFreezStop();
            }
        }

    };

    const fetchTaxDetails = async () => {
        handleFreezStart();
        const apiUrl = '/company-tax-details/' + id;
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if (res) {
            handleFreezStop();
            if (res.success == true) {
                setTaxFlag(true);
                setTaxDetails(res.company1099Info);
            } else {
                handleFreezStop();
            }
        }
    };

    const fetchUserTypes = async() => {
        handleFreezStart();
        const apiUrl = '/fetchUserTypes';
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if(res) {
            handleFreezStop();
            setUserTypeList(res);
        }
    }

    //fetch Company AID list start
    const getAidlist = async() => {
        handleFreezStart();
        const apiUrl = '/fetchCompanyAIDList/' + id;
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if(res) {
            setAidNames(res.aidList);
        }
        handleFreezStop();
    }
    //fetch Company AID list end

    const checkUserRole = () => {
        var check = 1;
        const loginUser = JSON.parse(localStorage.getItem('user'));
        if(loginUser.roles_id  > 2) {
            check = 0;
        } 
        if(loginUser.roles_id >= 3 && loginUser.roles_id <= 5) {
            if(loginUser.company_master_id != id) {
                check = 0;
            }else{
                check = 1;
            }
        }
        if(check == 0) {
            navigate('/login');
            return false;
        }
        return true;
    };

    const fetchCompanyList = async () => {
        handleFreezStart();
        const apiUrl = '/fetchCompanyList';
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if (res) {
            handleFreezStop();
            const updatedCompanies = res.data.map((option) => {
                const firstLetter = option.company_name[0].toUpperCase();
                return {
                    firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
                    ...option,
                };
            });
            const sortedCompanies = updatedCompanies.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter));
            setCompanies(sortedCompanies);
        }
    }


  const ContentLayout =
  <>
    <Box sx={{ padding: 3 }}>
      <Typography variant="h5" fontWeight="bold">
        Company Details
      </Typography>

      {/* Tabs */}
      <Tabs value={tabValue} onChange={handleTabChange} sx={{ marginBottom: 2 }} TabIndicatorProps={{ sx: { backgroundColor: "#3FAC19" } }}>
        <Tab label="Company Details" sx={{ color: "#555", "&.Mui-selected": { color: "#3FAC19", fontWeight: "bold" }}} />
        <Tab label="Co-Brand PID List" sx={{ color: "#555", "&.Mui-selected": { color: "#3FAC19", fontWeight: "bold" }}}/>
        <Tab label="AID List" sx={{ color: "#555", "&.Mui-selected": { color: "#3FAC19", fontWeight: "bold" }}}/>
        <Tab label="Company Users" sx={{ color: "#555", "&.Mui-selected": { color: "#3FAC19", fontWeight: "bold" }}}/>
        <Tab label="Bank Details" sx={{ color: "#555", "&.Mui-selected": { color: "#3FAC19", fontWeight: "bold" }}}/>
        {user.roles_id <= 3 && taxFlag ?
            <Tab label="W-9 Details" sx={{ color: "#555", "&.Mui-selected": { color: "#3FAC19", fontWeight: "bold" }}}/>
        :''}
      </Tabs>

      {/* Render components based on tab selection */}
      {tabValue === 0 && <CompanyDetailsTab fetchCompanyDetails={fetchCompanyDetails} company_details={company_details} user={user} company_id={id} bank_details={bank_details} taxFlag={taxFlag} handleFreezStart={handleFreezStart} handleFreezStop={handleFreezStop}/>}
      {tabValue === 1 && <CoBrandPIDsTab fetchCompanyDetails={fetchCompanyDetails} cobrandpids={cobrandpids} user={user} checkUserRole={checkUserRole} handleFreezStart={handleFreezStart} handleFreezStop={handleFreezStop}/>}
      {tabValue === 2 && <AIDsTab fetchCompanyDetails={fetchCompanyDetails} aids={aids} user={user} checkUserRole={checkUserRole} company_id={id} handleFreezStart={handleFreezStart} handleFreezStop={handleFreezStop}/>}
      {tabValue === 3 && <CompanyUsersTab fetchCompanyDetails={fetchCompanyDetails} company_details={company_details} aidusers={aidusers} user={user} checkUserRole={checkUserRole} userTypeList={userTypeList} aidNames={aidNames} company_id={id} handleFreezStart={handleFreezStart} handleFreezStop={handleFreezStop}/>}
      {tabValue === 4 && <BankDetailsTab fetchCompanyDetails={fetchCompanyDetails} company_details={company_details} bank_details={bank_details} user={user} checkUserRole={checkUserRole} filterhistory={filterhistory} fetchCompanyBankDetails={fetchCompanyBankDetails} handleFreezStart={handleFreezStart} handleFreezStop={handleFreezStop}/>}
      {user.roles_id <= 3 && taxFlag && tabValue === 5 && (
        <W9DetailsTab 
            fetchCompanyDetails={fetchCompanyDetails} 
            company_details={company_details} 
            tax_details={tax_details} 
            user={user} 
            checkUserRole={checkUserRole}
            handleFreezStart={handleFreezStart} 
            handleFreezStop={handleFreezStop} 
        />
        )}
    </Box>
  </>
  
  return (
    <div>
        <DefaultLayout content={ContentLayout} />
        {/* loader code start */}
        <div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 10 }}
                open={freez}
            // onClick={handleFreezStop}
            >
                <Freez />
            </Backdrop>
        </div>
        {/* loader code end */}
    </div>
);
};

export default CompanyDetailHome;
